define("discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-modifier", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-helper-button-group", "discourse/plugins/discourse-ai/discourse/components/ai-helper-loading", "discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list", "discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", "discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _emberModifier, _truthHelpers, _dButton, _ajax, _ajaxError, _decorators, _discourseI18n, _aiHelperButtonGroup, _aiHelperLoading, _aiHelperOptionsList, _diffModal, _thumbnailSuggestions3, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiComposerHelperMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "aiComposerHelper", [_service.service]))();
    #aiComposerHelper = (() => (dt7948.i(this, "aiComposerHelper"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "newSelectedText", [_tracking.tracked]))();
    #newSelectedText = (() => (dt7948.i(this, "newSelectedText"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "diff", [_tracking.tracked]))();
    #diff = (() => (dt7948.i(this, "diff"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "initialValue", [_tracking.tracked], function () {
      return "";
    }))();
    #initialValue = (() => (dt7948.i(this, "initialValue"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "customPromptValue", [_tracking.tracked], function () {
      return "";
    }))();
    #customPromptValue = (() => (dt7948.i(this, "customPromptValue"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "lastUsedOption", [_tracking.tracked], function () {
      return null;
    }))();
    #lastUsedOption = (() => (dt7948.i(this, "lastUsedOption"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "thumbnailSuggestions", [_tracking.tracked], function () {
      return null;
    }))();
    #thumbnailSuggestions = (() => (dt7948.i(this, "thumbnailSuggestions"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "showThumbnailModal", [_tracking.tracked], function () {
      return false;
    }))();
    #showThumbnailModal = (() => (dt7948.i(this, "showThumbnailModal"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "lastSelectionRange", [_tracking.tracked], function () {
      return null;
    }))();
    #lastSelectionRange = (() => (dt7948.i(this, "lastSelectionRange"), void 0))();
    MENU_STATES = this.aiComposerHelper.MENU_STATES;
    prompts = [];
    promptTypes = {};
    documentListeners = (() => (0, _emberModifier.modifier)(() => {
      document.addEventListener("keydown", this.onKeyDown, {
        passive: true
      });
      return () => {
        document.removeEventListener("keydown", this.onKeyDown);
      };
    }))();
    get helperOptions() {
      let prompts1 = this.currentUser?.ai_helper_prompts;
      prompts1 = prompts1.filter(p1 => p1.location.includes("composer")).filter(p1 => p1.name !== "generate_titles").map(p1 => {
        // AI helper by default returns interface locale on translations
        // Since we want site default translations (and we are using: force_default_locale)
        // we need to replace the translated_name with the site default locale name
        const siteLocale1 = this.siteSettings.default_locale;
        const availableLocales1 = JSON.parse(this.siteSettings.available_locales);
        const locale1 = availableLocales1.find(l1 => l1.value === siteLocale1);
        const translatedName1 = _discourseI18n.default.t("discourse_ai.ai_helper.context_menu.translate_prompt", {
          language: locale1.name
        });
        if (p1.name === "translate") {
          return {
            ...p1,
            translated_name: translatedName1
          };
        }
        return p1;
      });
      // Find the custom_prompt object and move it to the beginning of the array
      const customPromptIndex1 = prompts1.findIndex(p1 => p1.name === "custom_prompt");
      if (customPromptIndex1 !== -1) {
        const customPrompt1 = prompts1.splice(customPromptIndex1, 1)[0];
        prompts1.unshift(customPrompt1);
      }
      if (!this.currentUser?.can_use_custom_prompts) {
        prompts1 = prompts1.filter(p1 => p1.name !== "custom_prompt");
      }
      prompts1.forEach(p1 => {
        this.prompts[p1.id] = p1;
      });
      this.promptTypes = prompts1.reduce((memo1, p1) => {
        memo1[p1.name] = p1.prompt_type;
        return memo1;
      }, {});
      return prompts1;
    }
    get reviewButtons() {
      return [{
        icon: "exchange-alt",
        label: "discourse_ai.ai_helper.context_menu.view_changes",
        action: () => this.modal.show(_diffModal.default, {
          model: {
            diff: this.diff,
            oldValue: this.initialValue,
            newValue: this.newSelectedText,
            revert: this.undoAiAction,
            confirm: () => this.updateMenuState(this.MENU_STATES.resets)
          }
        }),
        classes: "view-changes"
      }, {
        icon: "undo",
        label: "discourse_ai.ai_helper.context_menu.revert",
        action: this.undoAiAction,
        classes: "revert"
      }, {
        icon: "check",
        label: "discourse_ai.ai_helper.context_menu.confirm",
        action: () => this.updateMenuState(this.MENU_STATES.resets),
        classes: "confirm"
      }];
    }
    get resetButtons() {
      return [{
        icon: "undo",
        label: "discourse_ai.ai_helper.context_menu.undo",
        action: this.undoAiAction,
        classes: "undo"
      }, {
        icon: "discourse-sparkles",
        label: "discourse_ai.ai_helper.context_menu.regen",
        action: () => this.updateSelected(this.lastUsedOption),
        classes: "regenerate"
      }];
    }
    get canCloseMenu() {
      if (document.activeElement === document.querySelector(".ai-custom-prompt__input")) {
        return false;
      }
      if (this.loading && this._activeAiRequest !== null) {
        return false;
      }
      if (this.aiComposerHelper.menuState === this.MENU_STATES.review) {
        return false;
      }
      return true;
    }
    onKeyDown(event1) {
      if (event1.key === "Escape") {
        return this.closeMenu();
      }
      if (event1.key === "Backspace" && this.args.data.selectedText && this.aiComposerHelper.menuState === this.MENU_STATES.triggers) {
        return this.closeMenu();
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "onKeyDown", [_decorators.bind]))();
    toggleAiHelperOptions() {
      this.updateMenuState(this.MENU_STATES.options);
    }
    static #_16 = (() => dt7948.n(this.prototype, "toggleAiHelperOptions", [_object.action]))();
    async updateSelected(option1) {
      this._toggleLoadingState(true);
      this.lastUsedOption = option1;
      this.updateMenuState(this.MENU_STATES.loading);
      this.initialValue = this.args.data.selectedText;
      this.lastSelectionRange = this.args.data.selectionRange;
      try {
        this._activeAiRequest = await (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
          method: "POST",
          data: {
            mode: option1.id,
            text: this.args.data.selectedText,
            custom_prompt: this.customPromptValue,
            force_default_locale: true
          }
        });
        const data1 = await this._activeAiRequest;
        // resets the values if new suggestion is started:
        this.diff = null;
        this.newSelectedText = null;
        this.thumbnailSuggestions = null;
        if (option1.name === "illustrate_post") {
          this._toggleLoadingState(false);
          this.closeMenu();
          this.thumbnailSuggestions = data1.thumbnails;
          this.modal.show(_thumbnailSuggestions3.default, {
            model: {
              thumbnails: this.thumbnailSuggestions
            }
          });
        } else {
          this._updateSuggestedByAi(data1);
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this._toggleLoadingState(false);
      }
      return this._activeAiRequest;
    }
    static #_17 = (() => dt7948.n(this.prototype, "updateSelected", [_object.action]))();
    cancelAiAction() {
      if (this._activeAiRequest) {
        this._activeAiRequest.abort();
        this._activeAiRequest = null;
        this._toggleLoadingState(false);
        this.closeMenu();
      }
    }
    static #_18 = (() => dt7948.n(this.prototype, "cancelAiAction", [_object.action]))();
    updateMenuState(newState1) {
      this.aiComposerHelper.menuState = newState1;
    }
    static #_19 = (() => dt7948.n(this.prototype, "updateMenuState", [_object.action]))();
    closeMenu() {
      if (!this.canCloseMenu) {
        return;
      }
      this.customPromptValue = "";
      this.updateMenuState(this.MENU_STATES.triggers);
      this.args.close();
    }
    static #_20 = (() => dt7948.n(this.prototype, "closeMenu", [_object.action]))();
    undoAiAction() {
      if (this.capabilities.isFirefox) {
        // execCommand("undo") is no not supported in Firefox so we insert old text at range
        // we also need to calculate the length diffrence between the old and new text
        const lengthDifference1 = this.args.data.selectedText.length - this.initialValue.length;
        const end1 = this.lastSelectionRange.y - lengthDifference1;
        this._insertAt(this.lastSelectionRange.x, end1, this.initialValue);
      } else {
        document.execCommand("undo", false, null);
      }
      // context menu is prevented from closing when in review state
      // so we change to reset state quickly before closing
      this.updateMenuState(this.MENU_STATES.resets);
      this.closeMenu();
    }
    static #_21 = (() => dt7948.n(this.prototype, "undoAiAction", [_object.action]))();
    _toggleLoadingState(loading1) {
      if (loading1) {
        this.args.data.dEditorInput.classList.add("loading");
        return this.loading = true;
      }
      this.args.data.dEditorInput.classList.remove("loading");
      this.loading = false;
    }
    _updateSuggestedByAi(data1) {
      this.newSelectedText = data1.suggestions[0];
      if (data1.diff) {
        this.diff = data1.diff;
      }
      this._insertAt(this.args.data.selectionRange.x, this.args.data.selectionRange.y, this.newSelectedText);
      this.updateMenuState(this.MENU_STATES.review);
    }
    _insertAt(start1, end1, text1) {
      this.args.data.dEditorInput.setSelectionRange(start1, end1);
      this.args.data.dEditorInput.focus();
      document.execCommand("insertText", false, text1);
    }
    static #_22 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-composer-helper-menu" {{this.documentListeners}}>
          {{#if (eq this.aiComposerHelper.menuState this.MENU_STATES.triggers)}}
            <ul class="ai-composer-helper-menu__triggers">
              <li>
                <DButton
                  @icon="discourse-sparkles"
                  @label="discourse_ai.ai_helper.context_menu.trigger"
                  @action={{this.toggleAiHelperOptions}}
                  class="btn-flat"
                />
              </li>
            </ul>
          {{else if (eq this.aiComposerHelper.menuState this.MENU_STATES.options)}}
            <AiHelperOptionsList
              @options={{this.helperOptions}}
              @customPromptValue={{this.customPromptValue}}
              @performAction={{this.updateSelected}}
            />
          {{else if (eq this.aiComposerHelper.menuState this.MENU_STATES.loading)}}
            <AiHelperLoading @cancel={{this.cancelAiAction}} />
          {{else if (eq this.aiComposerHelper.menuState this.MENU_STATES.review)}}
            <AiHelperButtonGroup
              @buttons={{this.reviewButtons}}
              class="ai-composer-helper-menu__review"
            />
          {{else if (eq this.aiComposerHelper.menuState this.MENU_STATES.resets)}}
            <AiHelperButtonGroup
              @buttons={{this.resetButtons}}
              class="ai-composer-helper-menu__resets"
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "ozkrfn6g",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"ai-composer-helper-menu\"],[4,[30,0,[\"documentListeners\"]],null,null],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"aiComposerHelper\",\"menuState\"]],[30,0,[\"MENU_STATES\",\"triggers\"]]],null],[[[1,\"        \"],[10,\"ul\"],[14,0,\"ai-composer-helper-menu__triggers\"],[12],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[32,1],[[24,0,\"btn-flat\"]],[[\"@icon\",\"@label\",\"@action\"],[\"discourse-sparkles\",\"discourse_ai.ai_helper.context_menu.trigger\",[30,0,[\"toggleAiHelperOptions\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"aiComposerHelper\",\"menuState\"]],[30,0,[\"MENU_STATES\",\"options\"]]],null],[[[1,\"        \"],[8,[32,2],null,[[\"@options\",\"@customPromptValue\",\"@performAction\"],[[30,0,[\"helperOptions\"]],[30,0,[\"customPromptValue\"]],[30,0,[\"updateSelected\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"aiComposerHelper\",\"menuState\"]],[30,0,[\"MENU_STATES\",\"loading\"]]],null],[[[1,\"        \"],[8,[32,3],null,[[\"@cancel\"],[[30,0,[\"cancelAiAction\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"aiComposerHelper\",\"menuState\"]],[30,0,[\"MENU_STATES\",\"review\"]]],null],[[[1,\"        \"],[8,[32,4],[[24,0,\"ai-composer-helper-menu__review\"]],[[\"@buttons\"],[[30,0,[\"reviewButtons\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"aiComposerHelper\",\"menuState\"]],[30,0,[\"MENU_STATES\",\"resets\"]]],null],[[[1,\"        \"],[8,[32,4],[[24,0,\"ai-composer-helper-menu__resets\"]],[[\"@buttons\"],[[30,0,[\"resetButtons\"]]]],null],[1,\"\\n      \"]],[]],null]],[]]]],[]]]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu.js",
      "scope": () => [_truthHelpers.eq, _dButton.default, _aiHelperOptionsList.default, _aiHelperLoading.default, _aiHelperButtonGroup.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiComposerHelperMenu;
});